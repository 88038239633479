@media (max-width: 575.98px) {
    table, thead, tbody, th, td, tr {
        display: block;
        border: solid #2f4050 1px;
    }
    thead tr {
        border: none;
        display: block;
    }
    td{
        text-align: center;
        border: none;
    }
    .color-row{
        margin: 0 auto;
    }
    td:empty {
        display:none;
    }
}
tbody>tr{
    cursor: pointer;
}
.cursor-pointer{
    cursor: pointer;
}