.lds-ring {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 51px;
	height: 51px;
	margin: 6px;
	border-radius: 50%;
	border-width: 6px;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
body{
	margin: 0 auto;
}
.loading-spinner{
	margin-top: calc(50vh - 25px);
	margin-left: calc(50vw - 25px);
}

.loading-spinner-component{
	margin-left: calc(50% - 25px);
	top:  calc(50% - 25px);
	position: absolute;
}

.loading-screen{
    width:100%; height:100vh; position: fixed; background-color: white; z-index: 9999
}

.loading-component{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    z-index: 9999;
}