svg {
    pointer-events: none;
}

path{
    pointer-events: auto;
}

.cls-1,.cls-2,.cls-3,.cls-4{
    fill:none;
    stroke:#000;
    stroke-width:10px;
}

.cls-1,.cls-4{
    stroke-linecap:round;
}

.cls-3,.cls-4{
    stroke-linejoin:round;
}

.outter{
    stroke:#000;
    stroke-width:10px;
    stroke-linecap:round;
    cursor: pointer;
}