.center-div{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.imagen{
    width:300px;
}

.opacity-7{
    opacity: 0.7;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

@keyframes fadeInOpacity {
	0% {
	    transform: translate(0, 5%);
		opacity: 0;
	}
	100% {
	     transform: translate(0%, 0%);
		opacity: 1;
	}
}

.square {
	width: 100%;
}

.square:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.logo{
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.facebook-color .btn-primary .btn-primary:hover .btn-primary:active .btn-primary:visited {
	background-color: #3b5998 !important;
}

.btn-facebook {
	background: #3B5998;
	color: #fff;
	border-color: #263961;
}
.btn-facebook:link, .btn-facebook:visited {
	color: #fff;
	border-color: #263961;
}
.btn-facebook:active, .btn-facebook:hover {
	background: #263961;
	color: #fff;
	border-color: #263961;
}

.btn-google {
	background: #dd4b39;
	color: #fff;
	border-color: #bf4634;
}
.btn-google:link, .btn-google:visited {
	color: #fff;
	border-color: #bf4634;
}
.btn-google:active, .btn-google:hover {
	background: #bf4634;
	color: #fff;
	border-color: #bf4634;
}