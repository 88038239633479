/* ---------------------------------------------------
	CUSTOM STYLE
----------------------------------------------------- */

.table td{
    vertical-align: middle !important;
}

/*
	DEMO STYLE
*/

.sidebar-item a,
.sidebar-item a:hover,
.sidebar-item a:focus {
	color: inherit !important;
	text-decoration: none !important;
	transition: all 0.3s;
}

.navbar {
	/*padding: 15px 10px;*/
	border: none;
	border-radius: 0;
	/*margin-bottom: 40px;*/
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
	box-shadow: none;
	outline: none !important;
	border: none;
}

i,
span {
	display: inline-block;
}

.container-logo{
	width: 50px;
	left: 50%;
	margin-left: -25px;
	position: relative;
}
.cursor-pointer{
  cursor: pointer;
}

.dropdown-toggle[aria-expanded="true"]:after {
    transform: rotate(180deg);
}

/*for animation*/
.dropdown-toggle:after {
    transition: 0.3s;
}

.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative;
}

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */

.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%;
}

/**
 * Fit the content to the aspect ratio
 */

.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/*
 * Additional code to resize/place the background image.
 */

.UserImage {
 background-size: cover;
}

/* ---------------------------------------------------
	SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
	display: flex;
	align-items: stretch;
}

#sidebar {
	min-width: 250px;
	max-width: 250px;
	transition: all 0.3s;
}

#sidebar.active {
	min-width: 80px;
	max-width: 80px;
	text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
	display: none;
}

#sidebar.active .sidebar-header strong {
	display: block;
}

#sidebar ul li a {
	text-align: left;
}

#sidebar.active ul li a {
	padding: 20px 10px;
	text-align: center;
	font-size: 0.85em;
}

#sidebar.active ul li a i {
	margin-right: 0;
	display: block;
	font-size: 1.8em;
	margin-bottom: 5px;
}

#sidebar.active ul ul a {
	padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
	top: auto;
	bottom: 10px;
	right: 50%;
	-webkit-transform: translateX(50%);
	-ms-transform: translateX(50%);
	transform: translateX(50%);
}

#sidebar .sidebar-header {
	padding: 20px;
}

#sidebar .sidebar-background {
	background: url('/img/header-bg.svg');
	background-size: cover;
}

#sidebar .sidebar-header strong {
	display: none;
	font-size: 1.8em;
}

#sidebar ul.components {
	padding: 20px 0;
}

#sidebar ul li a {
	padding: 10px;
	font-size: 1.1em;
	display: block;
	opacity: 0.7;
	cursor: pointer;
    color: #fff !important;
}

#sidebar ul li a:hover {
	color: #fff;
    text-decoration: none !important;
	opacity: 1;
	background: rgba(0,0,0,.1);
}

#sidebar ul li a i {
	margin-right: 10px;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
	color: #fff !important;
	/*background: #6d7fcc;*/
}

a[data-toggle="collapse"] {
	position: relative;
}

.dropdown-toggle::after {
	display: block;
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
}

ul ul a {
	font-size: 0.9em !important;
	padding-left: 30px !important;
	/*background: #6d7fcc;*/
}

ul.CTAs {
	padding: 20px;
}

ul.CTAs a {
	text-align: center;
	font-size: 0.9em !important;
	display: block;
	border-radius: 5px;
	margin-bottom: 5px;
}

a.download {
	/*background: #fff;*/
	color: #7386D5;
}

a.article,
a.article:hover {
	/*background: #6d7fcc !important;*/
	color: #fff !important;
}

/* ---------------------------------------------------
	CONTENT STYLE
----------------------------------------------------- */

#content {
	width: 100%;
	min-height: 100vh;
	transition: all 0.3s;
}

/* ---------------------------------------------------
	MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
	#sidebar {
		min-width: 80px;
		max-width: 80px;
		text-align: center;
		margin-left: -80px !important;
	}
	#sidebar ul {
		display: none;
	}
	#sidebar.active ul{
		display: block;
	}
	.dropdown-toggle::after {
		top: auto;
		bottom: 10px;
		right: 50%;
		-webkit-transform: translateX(50%);
		-ms-transform: translateX(50%);
		transform: translateX(50%);
	}
	#sidebar.active {
		margin-left: 0 !important;
	}
	#sidebar .sidebar-header h3,
	#sidebar .CTAs {
		display: none;
	}
	#sidebar .sidebar-header strong {
		display: block;
	}
	#sidebar ul li a {
		padding: 20px 10px;
	}
	#sidebar ul li a span {
		font-size: 0.85em;
	}
	#sidebar ul li a i {
		margin-right: 0;
		display: block;
	}
	#sidebar ul ul a {
		padding: 10px !important;
	}
	#sidebar ul li a i {
		font-size: 1.3em;
	}
	#sidebar {
		margin-left: 0;
	}
	#sidebarCollapse span {
		display: none;
	}

	.collapse:not(.show) {
		display: none !important;
	}

	.sidebar-is-changing{
		display: none !important;
	}
}